<!-- Müsaitliği Ayarlamadan Önce Eğitmen Seçimi İçin Card Bileşeni -->
<template>
  <b-card
      class="mx-0"
  >
    <div class="profile-image-wrapper d-flex justify-content-center">
      <div class="profile-image p-0">
        <b-avatar
            size="114"
            variant="light"
            :src="trainer.image"
        />
      </div>
    </div>
    <h3 class="text-center my-1">{{ `${trainer.name} ${trainer.last_name}` }}</h3>
    <div class="d-block text-center">
      <b-button
          variant="outline-primary"
          pill
          :to="{ name: 'avaibleTime',
                 params: {'trainerID': trainer.trainer_id}}"
      >
        {{ $t('Select') }}
      </b-button>
    </div>
  </b-card>
</template>

<script>
import {
  BAlert,
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCol,
  BForm,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupPrepend,
  BRow
} from 'bootstrap-vue'

export default {
  name: 'SelectTrainerCard',
  props: {
    trainer: {
      type: Object,
      required: true
    }
  },
  components: {
    BCard,
    BBadge,
    BAvatar,
    BRow,
    BCol,
    BCardBody,
    BCardText,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BAlert,
    BImg
  }
}
</script>
