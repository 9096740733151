<template>
  <div>
    <b-alert
        variant="light"
        show
        v-if="instructorConfirmData.length > 0">
      <div v-if="$i18n.locale === 'tr'" class="alert-heading bg-primary text-white rounded">

        <div class="d-flex justify-content-between align-items-between">
          <div v-if="$i18n.locale == 'tr'">
            <b>{{ instructorConfirmData.length }}</b> adet eğitmen bulundu
          </div>
          <div v-if="$i18n.locale == 'en'">
            <b>{{ instructorConfirmData.length }}</b> trainers found
          </div>
        </div>

      </div>
      <div v-if="$i18n.locale === 'en'" class="alert-heading bg-primary bg-lighten-1 text-white rounded">
        {{ instructorConfirmData.length }} active products found that you can make an appointment
      </div>
    </b-alert>
    <b-row class="kb-search-content-info match-height"
           v-if="instructorConfirmData.length > 0"
    >
      <b-col
          v-for="trainer in instructorConfirmData"
          :key="trainer.person_id"
          md="4"
          sm="6"
          class="kb-search-content"
      >
        <!-- Eğitmen kartları seçim yapılır ve seçilen eğitmenin müsaitliği ayarlanabilir -->
        <SelectTrainerCard :trainer="trainer"/>
      </b-col>
    </b-row>

    <template
        v-if="instructorConfirmData.length <= 0">
      <b-row class="d-flex justify-content-center align-items-center text-center">
        <p class="make-appointment-title">{{ $t('There Are No Active Instructors You Can Set Availability Of') }}</p>
      </b-row>
      <b-row class="d-flex justify-content-center align-items-center">
        <b-img
            class="trainer-svg"
            src="@/assets/images/badge/trainer.svg" fluid
        ></b-img>
      </b-row>
      <b-row class="d-flex justify-content-center">
        <b-button
            variant="primary" class="my-1 d-block"
            :to="{name: 'studio_instructors_list'}"
        >{{ $t('Invite An Instructor') }}
        </b-button>
      </b-row>
    </template>
  </div>
</template>

<script>
import {
  BAlert,
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCol,
  BForm,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupPrepend,
  BRow
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SelectTrainerCard from '@/components/SelectTrainerCard/SelectTrainerCard'

export default {
  components: {
    SelectTrainerCard,
    BCard,
    BBadge,
    BAvatar,
    BRow,
    BCol,
    BCardBody,
    BCardText,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BAlert,
    BImg
  },
  data() {
    return {
      instructorConfirmBaseData: [],
      instructorConfirmData: [],
      instructorNotConfirmBaseData: [],
      instructorNotConfirmData: []
    }
  },
  methods: {
    getTrainerList() {
      this.$database.TrainerService.get_studio_trainers_with_img(
          this.$store.getters['auth/userInfo'].company_branch_id
      )
          .then((res) => {
            if (res.is_success === true || res.is_success === 1) {
              res.result.map((el) => {
                if (el.is_confirm === true || el.is_confirm === 1) {
                  this.instructorConfirmBaseData.push({
                    ...el,
                    full_name: el.name + ' ' + el.last_name,
                  })
                } else {
                  this.instructorNotConfirmBaseData.push({
                    ...el,
                    full_name: el.name + ' ' + el.last_name,
                    invite_created_date: this.dateFormat(el.created_date),
                    invite_resend_invite_date: this.dateFormat(
                        el.resend_invite_date
                    ),
                  })
                }
              })
              let key = 'person_id'

              let arrayUniqueByKey = [
                ...new Map(
                    this.instructorNotConfirmBaseData.map((item) => [item[key], item])
                ).values(),
              ]
              this.instructorNotConfirmBaseData = []
              arrayUniqueByKey.map((el) => {
                this.instructorNotConfirmBaseData.push(el)
              })
              this.instructorNotConfirmData = this.instructorNotConfirmBaseData

              let arrayUniqueByKey2 = [
                ...new Map(
                    this.instructorConfirmBaseData.map((item) => [item[key], item])
                ).values(),
              ]
              this.instructorConfirmBaseData = []
              arrayUniqueByKey2.map((el) => {
                this.instructorConfirmBaseData.push(el)
              })
              this.instructorConfirmData = this.instructorConfirmBaseData
            }
          })
    },
  },
  created() {
    this.getTrainerList()
  },
  directives: {
    Ripple,
  },
}
</script>

<!-- stillendirme -->
<style lang="scss" src="@/styles/scss/selectTrainer.scss" scoped> </style>
